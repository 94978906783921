<template>
  <div v-click-outside="vcoConfig">
    <VueGoodTable
      ref="table"
      :columns="columns"
      :rows="rows"
      styleClass="vgt-table custom-vgt-table"
      @on-row-click="onRowClick"
      :row-style-class="rowStyleClassFn"
      :loading.sync="isLoading"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <!-- Empty state -->
        <span
          class="custom-vgt-table__empty rounded-circle d-block"
          v-if="
            props.formattedRow[props.column.field] == null ||
            props.formattedRow[props.column.field].length === 0
          "
        >
        </span>

        <template v-else-if="props.column.html">
          <slot
            name="custom-html-cell"
            :field="props.column.field"
            :value="props.row[props.column.field]"
            :row="props.formattedRow"
          ></slot>
        </template>

        <!-- Default -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- Table actions -->
      <template slot="table-actions">
        <slot name="custom-table-actions"></slot>
      </template>

      <!-- Empty state -->
      <div class="text-center" slot="emptystate">{{ emptyState }}</div>
    </VueGoodTable>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    excludeFromClickOutside: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    emptyState: {
      type: String,
      default: "No data for table",
    },
    from: {
      type: String,
      default: "assigning",
    },
  },
  components: {
    VueGoodTable,
  },
  data() {
    return {
      vcoConfig: {
        handler: this.onClickOutside,
        middleware: this.vcoMiddleware,
      },
      selectedRowId: null,
    };
  },
  methods: {
    onRowClick(params) {
      const rowIdx = this.rows.findIndex((row) => row.id === params.row.id);
      this.rows.forEach((row) => this.$set(row, "vgtSelected", false));
      this.selectedRowId = params.row.id;
      this.$set(this.rows[rowIdx], "vgtSelected", true);
    },
    rowStyleClassFn(row) {
      const rowClasses = [];
      if (row.id === this.selectedRowId) rowClasses.push("active");
      if (this.from == "rent_log") {
        if (row.assigned === true) rowClasses.push("highlighted-info");
      } else {
        if (row.assigned === true) rowClasses.push("highlighted");
      }
      return rowClasses.join(" ");
    },
    onClickOutside() {
      this.selectedRowId = null;
    },
    vcoMiddleware(event) {
      if (this.excludeFromClickOutside.length) {
        const excludedElId = this.excludeFromClickOutside.find((selector) => {
          const el = document.querySelector(selector);
          if (el) return el.contains(event.target);
          return false;
        });

        if (excludedElId) return false;
      }

      return true;
    },
  },
  watch: {
    selectedRowId(newVal) {
      this.$emit("rowSelect", newVal);
    },
  },
};
</script>

<style lang="scss">
.vgt-responsive {
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.custom-vgt-table {
  box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: 0.05);
  border: none !important;

  @include md {
    font-size: 1.15rem !important;
  }

  @include sm {
    font-size: 1.05rem !important;
  }

  thead th {
    background: white !important;
    color: $black;

    &.sortable {
      button {
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  tr {
    &:last-of-type {
      td {
        border: none;
      }
    }

    &:not(:last-of-type) td {
      border-bottom: 1px solid #e0e0e0;
    }

    td:not(:first-of-type) {
      text-align: center;

      span {
        margin: auto;
      }
    }

    th:not(:first-of-type) {
      text-align: center;
    }

    cursor: pointer;

    &.active {
      background: rgba($color: #c4c4c4, $alpha: 0.2);
    }

    &.highlighted {
      box-shadow: inset 0 0 0 2px $primary;
      position: relative;

      td:first-of-type span {
        &::before {
          @include pseudo(block, absolute, attr(data-content));
          background: $primary;
          width: 10rem;
          height: 30px;
          color: white;
          top: -30px;
          left: 0;
          padding-left: 11px;
          font-weight: 500;
          font-size: 1.125rem;
        }
      }
    }

    &.highlighted-info {
      box-shadow: inset 0 0 0 2px $info;
      position: relative;

      td:first-of-type span {
        &::before {
          @include pseudo(block, absolute, attr(data-content));
          background: $info;
          width: 10rem;
          height: 30px;
          color: white;
          top: -30px;
          left: 0;
          padding-left: 11px;
          font-weight: 500;
          font-size: 1.125rem;
        }
      }
    }

    td {
      vertical-align: middle;
    }
  }

  &__empty {
    width: 7.61px;
    height: 7.61px;
    background: #606266;
  }
}

.vgt-selection-info-row {
  display: none;
}

table.custom-vgt-table tr.clickable:hover {
  background: rgba($color: #c4c4c4, $alpha: 0.2) !important;
}

table.custom-vgt-table {
  .vgt-global-search {
    border: none;
    background: transparent;
  }
}

.vgt-inner-wrap {
  border-radius: 0 !important;
  box-shadow: none !important;

  .vgt-global-search {
    border: none;
    background: none;
    margin-bottom: 1.1875rem;
  }
}

.vgt-global-search__actions {
  margin-left: 0;
}

.vgt-global-search__input {
  padding: 0;
}
</style>
