<template>
  <Modal
    modalId="report-candidate-modal"
    :no-close-on-backdrop="false"
    :hide-header="true"
    :hide-footer="true"
  >
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="fs-30px fw-700">
            {{ $t("Report candidate") }}
          </div>
          <p class="text-gray mt-2">
            {{ $t("What is the reason you want to report this candidate?") }}
          </p>
        </div>
      </div>
      <p
        class="text-danger fw-bold text-capitalize"
        v-if="getErrors.reportCandidate && getErrors.reportCandidate.length"
      >
        {{ getErrors.reportCandidate }}
      </p>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div>
            <label class="text-dark">{{ $t("Reason") }}</label>
            <Textarea name="reason" v-model="reportCandidate.reason" rules="required" />
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <BButton
                :disabled="getLoadings.reportCandidate"
                type="submit"
                block
                variant="primary"
              >
                {{ $t("Send report") }}
                <b-spinner
                  v-if="getLoadings.reportCandidate"
                  label="Spinning"
                  small
                  variant="white"
                ></b-spinner>
              </BButton>
            </div>
            <div class="col-md-12 pt-0">
              <button
                class="btn btn-white btn-block"
                type="button"
                @click="$bvModal.hide('report-candidate-modal')"
              >
                {{ $t("Cancel") }}
              </button>
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </div>

    <template #footer> </template>
  </Modal>
</template>

<script>
import Modal from "@/components/ui/Modal.vue";

import { ValidationObserver } from "vee-validate";
import Textarea from "../../ui/form/controls/Textarea.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Modal,
    ValidationObserver,
    Textarea,
  },
  data() {
    return {
      reportCandidate: {
        reason: "",
        comment: "",
        report_admin: false,
        admin_report_reason: "",
      },
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    reportReasons() {
      return [
        { text: this.reportLocale.reasons[0], value: "" },
        { text: this.reportLocale.reasons[1], value: "reason1" },
        { text: this.reportLocale.reasons[2], value: "reason2" },
        { text: this.reportLocale.reasons[3], value: "reason3" },
      ];
    },
    inviteTimeOptions() {
      let hours = [{ text: "Select viewing time", value: "" }];

      for (let i = 9; i <= 17; i++) {
        const hour = i < 10 ? `0${i}` : i;
        hours.push({ text: `${hour}:00`, value: `${hour}:00` });
      }
      return hours;
    },
    reportLocale() {
      return this.$t("broker.assigningTable.reportCandidateModal");
    },
  },
  methods: {
    onSubmit() {
      this.$emit("report-candidate", { ...this.reportCandidate });
    },
  },
};
</script>

<style lang="scss">
.report-modal {
  &__candiate {
    font-size: 1.875rem;
    @include xs {
      font-size: 1.5rem;
    }
  }
  &__btn {
    width: 13.875rem;
  }
  &__comment {
    textarea {
      padding: 1rem 0.75rem;
    }
  }
}
</style>
